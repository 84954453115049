import React from 'react'
import {Helmet} from 'react-helmet'
import ModalImage from 'react-modal-image-responsive'
import Layout from '../components/layout'
import './client-dashboard.css'
import ClientDashboardImage from '../images/ClientDashboard/Client Dashboard.png'
import ClientDashboardBrowser from '../images/ClientDashboard/Client Dashboard Browser.png'
import ClientDashboardWireframe from '../images/ClientDashboard/Client Dashboard Wireframe.png'
import ClientDashboardInterim from '../images/ClientDashboard/Client Dashboard Interim.png'
import OldClientWebsite from '../images/ClientDashboard/Old Client Website.png'

export default function ClientDashboard() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex" />
        <title>Leland Riordan - Portfolio - Client Dashboard</title>
      </Helmet>
      <div className="case-study-feature">
        <img src={ClientDashboardImage} className="case-study-feature-image" alt='Portfolio Workbench Individual Page'/>
      </div>
      <div className="main-content case-study-content">
        <div className="row row-max">
          <h1 className="case-study-title">Client Dashboard</h1>
          <p className="lead">
            Aggregating information from across products at Cambridge Associates into an easy to use hub, the Client Dashboard is the centerpiece of the Cambridge Associates client experience.
            I won the firm's Innovation Award for shepherding for launching this product in 2015.
          </p>
          <p>
            Cambridge Associates is an elite asset management and portfolio consulting company with over $500 billion under advisement.
            It's most famous for managing the money of the world's leading endowments and family offices (Harvard, Yale, Bill & Melinda Gates Foundation, Oprah, etc).
            While the firm had an existing website, it was ancient and almost all client interactions were done in person with carefully crafted powerpoints and leather bound reports.
            This all changed in 2014 when the firm's founders retired and new blood took over including a new CTO from Sapient.
          </p>
          <p>
            As part of this transition, the new CTO hired me to revitilize the client experience.
            The center piece of this work was a new product called the Client Dashboard.
            The goal was to centralize client, portfolio and research information to give users launch pad into each vertical.
          </p>

          <figure className="column bg-gradient m-2">
            <figcaption>Old Client Website Circa 2014</figcaption>
              <ModalImage
                small={OldClientWebsite}
                large={OldClientWebsite}
                className="case-study-image"
                hideDownload={true}
                hideZoom={true}
                alt="Old Client Website Circa 2014"
              />
          </figure>

          <p>
            The old client website was a sad static html website from the early 2000s.
            It exposed no investment information to the user about their accounts and was essentially a static list of links to applications and PDFs.
            Client information was siloed in different applications across the firm, with competing executives controlling each disperate piece.
            I would have to find shared alignment across these business with a user centered product design.
          </p>
          <p>
            The development teams were swamped working on the Optica redesign so I started by conducting user research.
            I tagged along with investment teams to client meetings, sent surveys, analyzed analytics and shadowed members of the investment teams.
            It was clear that users wanted to see live portfolio information, capital markets research, notifications and a simple unified navigation across products.
            Armed with this information, I set up whiteboard sessions with the executives, product teams and technical stakeholders to iron out our strategy and goals.
            We came up with a year long strategy that broke the larger redesign into two phases.
          </p>

          <figure className="column bg-gradient m-2">
            <figcaption>Early Client Dashboard Wireframe</figcaption>
              <ModalImage
                small={ClientDashboardWireframe}
                large={ClientDashboardWireframe}
                className="case-study-image"
                hideDownload={true}
                hideZoom={true}
                alt="Early Client Dashboard Wireframe"
              />
          </figure>

          <p>
            In the first phase, our goals were to set up the platform, retire the old website and tackle two of the four items (capital markets research and unified navigation).
            The Optica team was busy and we would not have access portfolio data, but access to these apps was critical.
            I decided to put in a large section at the top of the page with deep links to each product.
            If we couldn't give users immeadiate portfolio information, at least we could make them easily accessible.
            My team and I created wireframes and then went back and forth with the product team to nail down the content and user flow for each user type.
            Next, we went higher fidelity by creating mockups in Sketch to finalize content with each business unit.
            Finally, we pulled it all together into a clickable prototype using inVision for the final sign off and developer hand off.
          </p>
        </div>

        <figure className="column bg-gradient m-2">
          <figcaption>Client Dashboard Phase 1</figcaption>
            <ModalImage
              small={ClientDashboardInterim}
              large={ClientDashboardInterim}
              className="case-study-image"
              hideDownload={true}
              hideZoom={true}
              alt="Client Dashboard Phase 2"
            />
        </figure>

        <div className="row row-max">
          <p>
            The plan for Phase 2 was to integrate portfolio data and notifications.
            It was incredibly important because it was timed to coincide with the launch of the redesigned Optica product suite.
            I worked with the firm's leading portfolio managers and quants to map out the most relevant data and the most intuitive way to display it.
            We came up with a plan to display small analytics widgets for each Optica app that exposed summaries of their investments.
            Each widget would deep link into the underlying application for more information.
            For notifications, we came up with a sticky sidebar that unified notifations from across products.
            It was integrated into the new global navigation and followed users as they navigated between applications for quick and easy access.
          </p>
        </div>

        <figure className="column bg-gradient m-2">
          <figcaption>Client Dashboard Phase 2</figcaption>
            <ModalImage
              small={ClientDashboardImage}
              large={ClientDashboardImage}
              className="case-study-image"
              hideDownload={true}
              hideZoom={true}
              alt="Client Dashboard Phase 2"
            />
        </figure>

        <div className="row row-max">
          <h2>Outcome</h2>
          <p>
            After about six months of development, the first phase of the Client Dashboard launched in the summer of 2015 with the second following 6 months later.
            It was a massive success - traffic increased, time logged increased and leads for account upgrades increased.
            In the annual survey sent to clients, many wrote that the Client Dashboard was a key differentiator compared to our competitors.
            As a result of the success, I won the firm's 2015 Innovation Award and was promoted to manage UX and front end development firmwide.
          </p>
        </div>
      </div>
    </Layout>
  )
}
